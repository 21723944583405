import { useState } from "react";
import { FilterSectionWrapper } from "./styles";

export default function FilterSection({handleFilter}) {
  const months = ['01', '02', '03', '04', '05', '06' ,'07', '08', '09','10', '11', '12'];
  const years = [2020, 2021, 2022, 2023];
  const companies = ['Todas', 'Empresa 1', 'Empresa 2', 'Empresa 3'];
  const [company, setCompany] = useState(companies[0]);
  const situations = ['SELECIONE', 'DEVEDOR', 'CREDOR'];
  const [situation, setSituation] = useState(situations[0]);
  const [name, setName] = useState('');
  const [month, setMonth] = useState(months[0]);
  const [year, setYear] = useState(years[0]);

  return (
    <FilterSectionWrapper>
      <label>
        Empresa
        <select value={company} onChange={e => setCompany(e.target.value)}>
          {companies.map((company, index) => <option key={index} value={company}>{company}</option>)}
        </select>
      </label>

      <label>
        Mês
        <select value={month} onChange={e => setMonth(e.target.value)}>
          {months.map((month, index) => <option key={index} value={month}>{month}</option>)}
        </select>
      </label>

      <label>
        Ano
        <select value={year} onChange={e => setYear(e.target.value)}>
          {years.map(year => <option value={year}>{year}</option>)}
        </select>
      </label>

      <label>
        Situação
        <select value={situation} onChange={e => setSituation(e.target.value)}>
          {situations.map(situation => <option value={situation}>{situation}</option>)}
        </select>
      </label>
      <button onClick={() => handleFilter(name, month, year, situation)}>Filtrar</button>
    </FilterSectionWrapper>
  )
}