import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import packageJson from '../../../package.json';
import { Form } from '../../components/Defaults';
import Input from '../../components/Input';
import Logo from '../../assets/tclogo.png';
import LoginImage from '../../assets/login-image.svg';
import { LoginWrapper } from './styles';
import { login } from '../../services/api';
import { GlobalContext } from '../../contexts/global';

export default function Login() {
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const { setSession, setMenuButtons } = useContext(GlobalContext);
  const navigate = useNavigate();

  function handleLogin(e) {
    e.preventDefault();
    if(!email || !password) {
      alert('Preencha os campos corretamente!');
    } else {
      remember 
        ? localStorage.setItem('email', email)
        : localStorage.removeItem('email');
      login(email, password).then(json => {
        if(json.error) {
          alert(json.message);
        } else {
          const group = json.data.grup.split('|');
          const companies = json.data.empr.map(company => ({
            id: company.EMP_CODIGO,
            fantasyName: company.EMP_FANTASIA,
            corporateName: company.EMP_RAZAO,
            cnpj: company.EMP_CNPJ
          }))
          console.log(json.data); 
          setSession(() => ({
            id: json.data.codg,
            name: json.data.nome,
            email,
            companies,
            group: {
              id: group[0],
              label: group[1]
            },
            // permissions: json.data.perm[0].GRP_ACOES.map(action => ({
            //   id: action.PER_ACAO,
            //   label: action.ACA_DESCRITIVO
            // }))
          }));
          setMenuButtons(json.data.menu);
          navigate('/painel');
        }
      })
    }
  }

  useEffect(() => {
    setSession(null);
    if(localStorage.getItem('email')) {
      setRemember(true)
      setEmail(localStorage.getItem('email'));
    }
  }, []);

  return (
    <LoginWrapper className="container">
      <img src={LoginImage} />
      <Form as="form">
        <img className="logo" src={Logo} style={{ width: '20rem' }} />
        <Input type="email" value={email} setValue={setEmail} label="E-mail" />
        <Input type="password" value={password} setValue={setPassword} label="Senha" />
        <label className="remember">
          <input type="checkbox" onChange={() => setRemember(old => !old)} checked={remember} />
          Lembrar e-mail
        </label>
        <button onClick={e => handleLogin(e)}>Login</button>
      </Form>
      <span className="version">v{packageJson.version}</span>
    </LoginWrapper>
  );
}
