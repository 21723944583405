import Menu from '../../../../components/Menu';
import Input from '../../../../components/Input';
import Table from '../../../../components/Table';
import { DefaultContainer, Divider, DividerNoMargin, Flex, InputRow } from '../../../../components/Defaults';
import { FormBox } from './styles';
import RadioGroup from '../../../../components/RadioGroup';

export default function BarCode() {  
  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Consulta dos Códigos de Barra</h1>
        <Flex>
          <FormBox width="100%">
            <h2>Filtros</h2>
            <InputRow>
              <Input type="text" label="Descritivo" width="60%" />
              <Input type="text" label="EAN" width="20%"/>
              <Input type="text" label="NCM" width="10%"/>
              <Input type="text" label="CEST" width="10%"/>
            </InputRow>
            <Flex between>
              <RadioGroup label="Uso & Consumo" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
              <RadioGroup label="Ativo Imobilizado" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
              <RadioGroup label="Combustíveis" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
            </Flex>
            <InputRow>
              <Input type="text" label="Usuário" width="25%" />
              <Input type="select" label="Status" width="25%" options={[
                {id: 1, label: 'NÃO AUDITADO'}
              ]}/>
            <div style={{ width: '50%' }}>
            <h3>Impostos Estaduais</h3>
            <Flex between>
              <FormBox width="40%">
                <InputRow className="center">
                  <h2 className="small">ICMS (Entrada)</h2>
                  <Input type="text" label="CST" width="40%" />
                  <Input type="text" label="Alíquota" width="40%" />
                </InputRow>
              </FormBox>
              <FormBox width="40%">
                <h2 className="small">ICMS (Saída)</h2>
                <InputRow className="center">
                  <Input type="text" label="CST" width="40%"/>
                  <Input type="text" label="Alíquota" width="40%"/>
                </InputRow>
              </FormBox>            
            </Flex>
            </div>
            </InputRow>
              <button className="medium">Filtrar</button>
          </FormBox>
          
        </Flex>
        <Table header={['EAN', 'Descritivo', 'NCM', 'CEST', 'ICMS (ENT)', 'ICMS (SAI)', 'Status', 'Usuário']} body={[
          [1912121212129, 'A & E Farmácia LTDA', 1212131313, '0,90', '0,00', '0,00', 'Não Auditado', 'Joyce'],
          [1912121212129, 'A & E Farmácia LTDA', 1212131313, '0,90', '0,00', '0,00', 'Não Auditado', 'Joyce'],
          [1912121212129, 'A & E Farmácia LTDA', 1212131313, '0,90', '0,00', '0,00', 'Não Auditado', 'Joyce'],
          [1912121212129, 'A & E Farmácia LTDA', 1212131313, '0,90', '0,00', '0,00', 'Não Auditado', 'Joyce'],
        ]}/>

        <Divider />

        <FormBox width="100%">
            <h2>Atualização</h2>
            <InputRow>
              <Input type="text" label="Descritivo" width="70%"/>
              <Input type="text" label="NCM" width="15%"/>
              <Input type="text" label="CEST" width="15%"/>
            </InputRow>
            <DividerNoMargin style={{ marginBottom: 8 }} />
            <Flex between>
              <RadioGroup label="Uso & Consumo" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
              <RadioGroup label="Ativo Imobilizado" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
              <RadioGroup label="Combustíveis" data={[
                {id: 1, label: 'Todos'},
                {id: 2, label: 'Marcados'}, 
                {id: 3, label: 'Não Marcados'}, 
              ]}/>
            </Flex>
                <h3>Impostos Estaduais</h3>
                <Flex center>
                  <FormBox width="40%">
                    <InputRow className="center">
                      <h2 className="small">ICMS (Entrada)</h2>
                      <Input type="text" label="CST" width="40%" />
                      <Input type="text" label="Alíquota" width="40%" />
                    </InputRow>
                  </FormBox>
                  <FormBox width="40%">
                    <h2 className="small">ICMS (Saída)</h2>
                    <InputRow className="center">
                      <Input type="text" label="CST" width="40%"/>
                      <Input type="text" label="Alíquota" width="40%"/>
                    </InputRow>
                  </FormBox>            
                </Flex>
            <Flex center>
              <button style={{ width: '20%' }}>Aplicar</button>
              <button style={{ width: '20%' }}>👍</button>
            </Flex>
          </FormBox>


      </DefaultContainer>
    </>
  )
}