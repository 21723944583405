import styled from 'styled-components';

export const FormBox = styled.div`
  padding: 0 0.5rem;
  padding-top: 1.25rem;
  border: 2px solid var(--black);
  width: ${props => props.width ? props.width :'50%'};
  margin: 0.5rem;
  position: relative;

  h2 {
    position: absolute;
    top: -1rem;
    background: var(--red);
    color: var(--white);
    padding: 0.2rem 0.5rem;
    left: 1rem;

    &.small {
      font-size: 1rem;
      top: -0.75rem;
    }
  }

  h3 {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    background: var(--black);
    color: var(--white);
    padding: 0.2rem 0.5rem;
    text-align: center;
  }

  button {
    font-size: 1.1rem;
    margin: 0.5rem;
  }
`