import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalProvider from "./contexts/global";
import Dashboard from "./pages/Dashboard";
import Employees from "./pages/Employees";
import NewEmployee from "./pages/Employees/NewEmployee";
import Companies from "./pages/Companies";
import Groups from "./pages/Groups";
import NewCompany from "./pages/Companies/NewCompany";
import Login from "./pages/Login";
import BarCode from "./pages/Audit/StateTaxes/BarCode";
import SearchAndTruncateCEST from "./pages/Fiscal/SearchAndTrucateCEST";
import DisunifySped from "./pages/Fiscal/DisunifySped";
import SearchAndTrucateCESTNoGTIN from "./pages/Fiscal/SearchAndTrucateCESTNoGTIN";
import OperatorPanel from "./pages/Fiscal/OperatorPanel";
import Actions from "./pages/Actions";
import Permissions from "./pages/Permissions";
import ChangePassword from "./pages/ChangePassword";
import NewMobileUser from "./pages/Employees/NewMobileUser";
import MobileUsers from "./pages/Employees/MobileUsers";
import ResetUserPassword from "./pages/Employees/ResetUserPassword";

export default function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Routes>
          
          <Route path="/cadastro-usuario-mobile" element={<NewMobileUser />} />
          <Route path="/usuarios-mobile" element={<MobileUsers />} />
          <Route path="/" element={<Login />} />
          <Route path="/painel" element={<Dashboard />} />
          <Route path="/cadastro-empresa" element={<NewCompany />} />
          <Route path="/cadastro-usuario" element={<NewEmployee />} />
          
          <Route path="/usuarios" element={<Employees />} />
          <Route path="/empresas" element={<Companies />} />
          <Route path="/grupos" element={<Groups />} />
          <Route path="/acoes" element={<Actions />} />

          <Route path="/auditoria/impostos-estaduais/codigos-de-barra" element={<BarCode />} />
          <Route path="/pesquisar-e-truncar-cest-invalidos" element={<SearchAndTruncateCEST />} />
          <Route path="/pesquisar-e-truncar-sem-gtin" element={<SearchAndTrucateCESTNoGTIN />} />
          <Route path="/controle-sped-pis-confins" element={<OperatorPanel />} />
          <Route path="/desunificar-sped-pis-confins" element={<DisunifySped />} />

          <Route path="/permissoes" element={<Permissions />} />
          <Route path="/alterar-senha" element={<ChangePassword />} />
          <Route path="/alterar-senha-usuario" element={<ResetUserPassword />} />
        </Routes>
      </GlobalProvider>
    </BrowserRouter>
  );
}