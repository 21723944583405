import { RadioGroupWrapper } from "./styles";
import { Flex } from '../Defaults';

export default function RadioGroup({label, data, value, setValue }) {
  return (
    <RadioGroupWrapper>
      <label>{label}</label>
      <Flex>
        {data.map((item, index) => 
          <label key={index} className="checkbox">
            <input type="radio" />
            {item.label}
          </label>
        )}
      </Flex>
    </RadioGroupWrapper>
  )
}