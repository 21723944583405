import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Divider, InputRow, ButtonSend } from "../../../components/Defaults";
import { CompaniesWrapper } from './styles';
import Menu from '../../../components/Menu';
import Input from '../../../components/Input';
import { DiffRemoved } from '@styled-icons/octicons';
import { DiffAdded } from '@styled-icons/octicons';
import { GlobalContext } from "../../../contexts/global";
import { setUser } from "../../../services/api";

export default function NewEmployee() {
  const {groups, companies, userToEdit, setUserToEdit} = useContext(GlobalContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [cel, setCel] = useState('');
  const fomarmattedCompanies = companies.map(company => ({
    ...company,
    label: `${company.id} - ${company.fantasyName}`
  }));  
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [company, setCompany] = useState(companies[0].id);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [group, setGroup] = useState(groups[0].id);
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [address, setAddress] = useState('');
  const [UF, setUF] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [CEP, setCEP] = useState('');

  function handleSend(e) {
    e.preventDefault();
    if(!name || !email) {
      alert('Preencha as informações!');
    } else {
      const newUser = {
        USR_CODIGO: userToEdit ? userToEdit.id : null,
        USR_EMAIL: email,
        USR_GRUPO: group,
        USR_NOME: name,
        USR_EMPRESAS: selectedCompanies.map(company => ({
          EMP_CODIGO: company.id,
          EMP_FANTASIA: company.fantasyName,
          EMP_RAZAO: company.corporateName,
          EMP_CNPJ: company.cnpj
        }))
      }
      setUser(newUser).then(json => {
        if(json.error) {
          alert('Falha ao cadastrar usuário!');
        } else {
          alert(`Usuário ${userToEdit ? 'editado' : 'cadastrado'} com sucesso!`);
          setUserToEdit(null);
          setName('');
          setEmail('');
          setGroup(groups[0].id);
          setSelectedCompanies([]);
          navigate('/usuarios');
        }
      })
    }
  }

  function handleAddCompany() {
    setSelectedCompanies((old) => {
      if(old.find(el => el.id == company) || company == 0) {
        return old;
      } else {
        let array = [...old];
        array.unshift(fomarmattedCompanies.find(el => el.id == company));
        return array;
      }
    })
  }

  function handleRemoveCompany(companyId) {
    setSelectedCompanies(old => old.filter(company => company.id != companyId));
  }

  useEffect(() => {
    if(search) {
      const filtered = fomarmattedCompanies.filter(company => 
        company.label.toUpperCase().startsWith(search.toUpperCase()) 
        || company.fantasyName.toUpperCase().startsWith(search.toUpperCase('')))
      if(filtered.length) {
        setFilteredCompanies(filtered);
        setCompany(filtered[0].id)
      } else {
        setFilteredCompanies([{label: 'Sem resultados', id: 0}]);
        setCompany(0);
      }
       
    } else { 
      companies.length && setCompany(companies[0].id); 
      setFilteredCompanies([]);
    }
  }, [search]);

  useEffect(() => {
    if(userToEdit) {
      setName(userToEdit.name);
      setEmail(userToEdit.email);
      setGroup(userToEdit.group);
      setSelectedCompanies(userToEdit.companies);
    }
  }, []);

  return (
    <>
      <Menu />
      <DefaultContainer width="75%">
        {userToEdit
          ? <h1>Edição do Colaborador: {userToEdit.name}</h1>
          : <h1>Cadastro de Colaborador</h1>
        }
        <form onSubmit={handleSend}>
          <InputRow>
            <Input type="text" label="Nome" value={name} setValue={setName} />
            <Input type="email" label="Email" value={email} setValue={setEmail} />
            <Input type="select" label="Grupo" 
              options={groups} value={group} setValue={setGroup} width="50%"
            />
          </InputRow>

          <Divider />
          <InputRow>
            <Input type="text" label="Busque por uma empresa" value={search} setValue={setSearch} />
            <Input type="select" label="Selecione uma empresa para associar" 
              options={filteredCompanies.length ? filteredCompanies : fomarmattedCompanies} 
              value={company} setValue={setCompany}
              />
            <button onClick={handleAddCompany} type="button">
              <DiffAdded width={30} />
            </button>
          </InputRow>
          <CompaniesWrapper>
            {selectedCompanies.length 
              ? selectedCompanies.map((company, index) =>
                  <div key={index}>
                    <DiffRemoved size={30} color="var(--red)" onClick={() => handleRemoveCompany(company.id)} />
                    {company.label}
                  </div>
                )
              : <span>Sem Empresas Selecionadas</span>
            }
          </CompaniesWrapper>
          {/* <InputRow>
            <Input type="text" label="CPF" value={cpf} setValue={setCpf} width="50%" />
            <Input type="text" label="Telefone" value={phone} setValue={setPhone} width="50%" />
            <Input type="text" label="Celular" value={cel} setValue={setCel} width="50%" />
            <Input type="text" label="Cargo" value={group} setValue={setGroup} width="20%" />
            <Input style={{ marginLeft: 'auto' }} type="text" label="Salário" value={email} setValue={setEmail} width="15%"/>
          </InputRow> */}
          {/* <InputRow>
            <Input type="text" label="Endereço" value={address} setValue={setAddress} width="40%"/>
            <Input type="text" label="CEP" value={CEP} setValue={setCEP} width="10%"/>
            <Input type="text" label="Bairro" value={district} setValue={setDistrict} width="20%"/>
            <Input type="text" label="Cidade" value={city} setValue={setCity} width="20%"/>
            <Input type="text" label="UF" value={UF} setValue={setUF} width="5%"/>
          </InputRow>  

          <InputRow>
            <Input type="date" label="Nascimento" value={email} setValue={setEmail} width="15%"/>
          </InputRow>
          <Divider /> */}
          <ButtonSend>{userToEdit ? 'Editar' : 'Incluir'} Usuário</ButtonSend>
        </form>
      </DefaultContainer>
    </>
  )
}