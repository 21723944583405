import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, NewButton } from "../../../components/Defaults";
import Menu from '../../../components/Menu';
import Table from '../../../components/Table';
import { aproveMobileUser, getMobileUsers, setUser } from "../../../services/api";
import { MobileUsersFilter, NewUsersWrapper } from "./styles";

export default function MobileUsers() {
  const navigate = useNavigate();
  const [mobileUsers, setMobileUsers] = useState([]);
  const [filter, setFilter] = useState('0');
  const [filteredUsers, setFilteredUsers] = useState([]);

  function handleAprove(user) {
    aproveMobileUser(user.id).then(json => {
      if(json.error) {
        alert('Falha ao aprovar usuário!');
      } else {
        handleGetMobileUsers();
        alert('Usuário aprovado com sucesso!');
      }
    })
  }
  
  function handleEdit() {

  }
  
  function handleDelete() {

  }

  function handleGetMobileUsers() {
    getMobileUsers().then(json => {
      if(json.error) {
        alert('Falha ao recuperar usuários!');
      } else {
        setMobileUsers(() => 
          json.data.arr_socio.map(user => ({
            id: user.USR_CODIGO,
            uid: user.USR_UID,  
            email: user.USR_EMAIL || '',
            name: user.USR_NOME,
            obs: user.USR_DESCRITIVO,
            isDev: user.USR_DEVELOPER,
            isDriver: user.USR_DRIVER,
            isInative: user.USR_INATIVO,
            toAprove: !user.USR_HABILITADO
          }))
        )
      }
    })
  }

  useEffect(() => {
    handleGetMobileUsers();
  }, []); 

  useEffect(() => {
    if(filter === '0') {
      setFilteredUsers(mobileUsers);      
    } else {
      setFilteredUsers(() => mobileUsers.filter(user => {
        const boolean = filter === '2';
        console.log(user);
        return user.isInative == boolean;
      }))
    }
  }, [filter]);

  return (
    <>
      <Menu />
      <DefaultContainer width="75%">
        <h1>Usuários de dispositivos móvel</h1>
        <Flex>
          <MobileUsersFilter>
            <label>
              <input type="radio" checked={filter === '0'} onChange={e => setFilter('0')} />
              Todos
            </label>
            <label>
              <input type="radio" checked={filter === '1'} onChange={e => setFilter('1')} />
              Ativos
            </label>
            <label>
              <input type="radio" checked={filter === '2'} onChange={e => setFilter('2')} />
              Inativos
            </label>
          </MobileUsersFilter>
          <NewButton onClick={() => navigate('/cadastro-usuario-mobile')}>Incluir Usuário</NewButton>
        </Flex>
        <Table
          header={['Nome', 'Email', 'Dev?', 'Observação']} 
          body={filteredUsers.length > 0 ? filteredUsers : mobileUsers}
          type="mobileUsers"
          actions={{aprove: handleAprove, edit: handleEdit, delete: handleDelete}}
        />
      </DefaultContainer>
    </>
  )
}