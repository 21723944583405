import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, NewButton } from "../../components/Defaults";
import Menu from "../../components/Menu";
import SearchInTable from "../../components/SearchInTable";
import Table from "../../components/Table";
import { GlobalContext } from '../../contexts/global';

export default function Companies() {
  const navigate = useNavigate();
  const { companies } = useContext(GlobalContext);
  const [filtered, setFiltered] = useState([]);

  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Empresas</h1>
        <Flex>
          <SearchInTable values={companies} setFiltered={setFiltered} filter="corporateName" />
          <NewButton onClick={() => navigate('/cadastro-empresa')}>Incluir Empresa</NewButton>
        </Flex>
        <Table 
          header={['id', 'Razão Social', 'Nome Fantasia', 'CNPJ']} 
          body={filtered.length ? filtered : companies}
          type="companies"
        />
      </DefaultContainer>
    </>
  )
}