import { useState } from "react";
import fileDownload from "js-file-download";

import Menu from "../../../components/Menu";
import { DefaultContainer } from "../../../components/Defaults";
import Input from "../../../components/Input";
import { cestInvalidos } from "../../../services/api";
import ButtonWithLoading from "../../../components/ButtonWithLoading";

export default function DisunifySped() {
  const [sped, setSped] = useState(null);
  const [progress, setProgress] = useState({upload: 0, download: 0});
  const [isLoading, setIsLoading] = useState(false)

  function handle() {
    function isUTF8Encoded(buffer) {
      const dataView = new DataView(buffer);
      let index = 0;
      while (index < dataView.byteLength) {
        const byte = dataView.getUint8(index);
        if (byte < 0x80) {
          index += 1;
        } else if ((byte & 0xe0) === 0xc0) {
          index += 2;
        } else if ((byte & 0xf0) === 0xe0) {
          index += 3;
        } else if ((byte & 0xf8) === 0xf0) {
          index += 4;
        } else {
          return false;
        }
      }
      return true;
    }
    if(!isLoading && sped) {
      setIsLoading(true);
      const reader = new FileReader(); 
      reader.onload = (event) => {
        let fileObj;
        const content = event.target.result;
        const isUTF8 = isUTF8Encoded(content);
        if (isUTF8) {
          const textDecoder = new TextDecoder('utf-8');
          const decodedContent = textDecoder.decode(content);
          fileObj = {
            name: sped.name,
            content: decodedContent,
          }
        } else {
          const textDecoder = new TextDecoder('windows-1252');
          const decodedContent = textDecoder.decode(content);
          fileObj = {
            name: sped.name,
            content: decodedContent,
          }
        }
        alert('Em construção!');
        // cestInvalidos(fileObj, setProgress).then(json => {
        //   setIsLoading(false);
        //   setProgress({upload: 0, download: 0});
        //   if(json.error) {
        //     alert(json.message);
        //   } else {
        //     const myblob = new Blob([json.data.file_content.replace(/%26/g, '&')], {
        //       type: 'text/plain'
        //     });
        //     fileDownload(myblob, json.data.file_name.replace(/%26/g, '&'));
        //   }
        // });
      }
      reader.readAsArrayBuffer(sped);
    } else if (!sped) {
      alert('É necessário adicionar um arquivo!');
    }
  }
  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1 style={{ marginBottom: 16 }}>Desunificar SPED PIS/COFINS</h1>
        <Input setValue={setSped} type="file" accept="text/plain" label="SPED Fiscal" />

        <ButtonWithLoading 
          onClick={handle}
          style={{ marginLeft: 'auto' }}
          isLoading={isLoading}
          text="Desunificar"
          progress={progress}
        />
      </DefaultContainer>
    </>
  )
}
