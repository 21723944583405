import styled from "styled-components";

export const ModalFormWrapper = styled.section`
  width: 50vw;

  h2.notInfos {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  div.infos {
    margin: 0.5rem 0;
    display: flex;

    span:first-child {
      display: flex;
      width: 5rem;
    }

    span:last-child {
      border-bottom: 2px solid var(--red);
    }

  }
  
  div.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &.full {
      flex: 1;
    }

    span {
      min-width: 7.5rem;
    }
    input, select {
      margin-left: auto;
      height: 2rem;
      width: 10rem;

      &.full {
        width: 100%;
      }
    }
  }

  button {
    margin-left: auto;
    margin-top: 1.5rem;
    width: 10rem;
  }

`