import styled from "styled-components";

export const TableWrapper = styled.main`
  padding: 1rem 0;
  
  * {
    border: none;
  }
  
  table {
    background: var(--white);
    width: 100%;
        
    th {
      color: var(--white);
      background: var(--black);
      padding: 0.5rem;
      font-size: 1.1rem;
      text-align: left;
    }

    tr {
      cursor: pointer;
      :hover {
        background: #bbb;
      }
  
    }

    td {
      padding: 0.5rem;
      font-size: 0.85rem;
      
      &.actions {
        display: flex;

        button {
          margin: 0.25rem;
          
          &.confirm {
            background-color: #269449;
          }

          &.edit {
            /* background-color: #262d94; */
            background-color: #444;
          }
        }
      }

      button {
        max-height: 2rem; 
        min-height: 2rem;
        padding: 0 0.5rem;
        font-size: 1rem; 
      }
    }
  }

`