import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getActions, getCompanies, getGroups, getUsers } from '../services/api';

export const GlobalContext = createContext({});

export default function GlobalProvider({ children }) {
  const navigate = useNavigate();
  const [menuButtons, setMenuButtons] = useState([]);
      // {id: 2, title: 'Acompanhamento das Metas', subItems: [
      //   {id: 6, title: 'Painel do Operador', path: '/painel-operador'},
      // ]}
      // {id: 2, title: 'Fiscal 2', path: '/funcionarios', showDialog: false},
      // {id: 3, title: 'Fiscal 3', path: '/empresas', showDialog: false},
      // {id: 4, title: 'Fiscal 4', path: '/auditoria/impostos-estaduais/codigos-de-barra', showDialog: false, otherPermissions: [{id: 41, title: 'Teste'}]},
  const [companies, setCompanies] = useState([]);
  const [session, setSession] = useState(null);
  const [users, setUsers] = useState([]); 
  const [userToEdit, setUserToEdit] = useState(null);
  const [groups, setGroups] = useState([]); 
  const [actions, setActions] = useState([]); 

  useEffect(() => {
    getCompanies().then(json => {
      if(json.error) {
        alert('Falha ao resgatar empresas:', json.message);
      } else {
        setCompanies(() => 
          json.data.arrEmpresas.map(company => ({
            id: company.EMP_CODIGO,
            fantasyName: company.EMP_FANTASIA,
            corporateName: company.EMP_RAZAO,
            cnpj: company.EMP_CNPJ
          }))
        )
      }
    });

    getUsers().then(json => {
      if(json.error) {
        alert('Falha ao resgatar usuários:', json.message);
      } else {
        console.log(json.data.arrUsuarios);
        setUsers(() => json.data.arrUsuarios.map(user => ({
          id: user.USR_CODIGO,
          email: user.USR_EMAIL,
          name: user.USR_NOME,
          group: user.USR_GRUPO,
          companies: user.arrEmpresas.map(company => ({
            id: company.EMP_CODIGO,
            fantasyName: company.EMP_FANTASIA,
            corporateName: company.EMP_RAZAO,
            cnpj: company.EMP_CNPJ,
            label: `${company.EMP_CODIGO} - ${company.EMP_FANTASIA}`
          }))
        })))
      }
    })

    getGroups().then(json => {
      if(json.error) {
        alert('Falha ao resgatar grupos:', json.message);
      } else {
        console.log(json.data.arrGrupos);
        setGroups(() => json.data.arrGrupos.map(group => ({
          id: group.GRP_CODIGO,
          label: group.GRP_DESCRITIVO,
          permissions: group.GRP_ACOES.map(permission => ({
            id: permission.PER_ACAO,
            label: permission.ACA_DESCRITIVO
          }))
        })))
      }
    });

    getActions().then(json => {
      if(json.error) {
        alert('Falha ao resgatar ações:', json.message);
      } else {
        console.log(json.data);
        setActions(() => json.data.arrAcoes.map(action => ({
          id: action.ACA_CODIGO,
          description: action.ACA_DESCRITIVO,
          isMenu: action.ACA_MENU,
          haveSubmenu: action.ACA_SUBMENU,
          path: action.ACA_PATH,
          root: action.ACA_ROOT
        })))
      }
    });

  }, []);

  useEffect(() => {
    !session && navigate('/');
  }, [session]);

  return (
    <GlobalContext.Provider value={{
      menuButtons, setMenuButtons,
      companies, setCompanies,
      users, setUsers, 
      groups, setGroups,
      actions, setActions,
      session, setSession,
      userToEdit, setUserToEdit
    }}>
      {children}
    </GlobalContext.Provider>
  )
}
