import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
  background: var(--red);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
  margin: 0.5rem 0;

  > label {
    width: 100%;
    border-bottom: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    height: 1.8rem;
  }
`