import { DefaultContainer, Divider, Flex, Form, InputRow, ButtonSend } from "../../../components/Defaults";
import Menu from '../../../components/Menu';
import Input from '../../../components/Input';
import { useState } from "react";

export default function NewCompany() {
  const [code, setCode] = useState('');
  const [social, setSocial] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [CNPJ, setCNPJ] = useState('');
  const [inscription, setInscription] = useState('');
  const [CNAE, setCNAE] = useState('');
  const [phone, setPhone] = useState('');
  const [cel, setCel] = useState('');
  const [contact, setContact] = useState('');
  const [site, setSite] = useState('');
  const [complement, setComplement] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [UF, setUF] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [CEP, setCEP] = useState('');
  const [number, setNumber] = useState('');

  function handleSend(e) {
    e.preventDefault();
  }

  return (
    <>
      <Menu />
      <DefaultContainer width="50%">
        <h1>Cadastro de Empresa</h1>
        <form onSubmit={handleSend}>
          <InputRow>
            <Input type="text" label="Código" value={code} setValue={setCode} width="25%" />
            <Input type="text" label="CNPJ" value={CNPJ} setValue={setCNPJ}/>
            {/* <Input type="text" label="Inscrição Estadual" value={inscription} setValue={setInscription} width="25%" /> */}
            {/* <Input type="text" label="CNAE 2.3" value={CNAE} setValue={setCNAE} width="25%" /> */}
          </InputRow>
            <Input type="text" label="Razão Social" value={social} setValue={setSocial} />
          <InputRow>
            <Input type="text" label="Nome Fantasia" value={fantasyName} setValue={setFantasyName} />
          </InputRow>
          {/* <InputRow>
            <Input type="text" label="Telefone" value={phone} setValue={setPhone} width="25%" />
            <Input type="text" label="Celular" value={cel} setValue={setCel} width="25%" />
            <Input type="email" label="Email" value={email} setValue={setEmail} width="25%"/>
          </InputRow>
          <Divider />
          
          <InputRow>
            <Input type="text" label="Endereço" value={address} setValue={setAddress} />
            <Input type="text" label="CEP" value={CEP} setValue={setCEP} width="15%"/>
            <Input type="text" label="Bairro" value={district} setValue={setDistrict} width="30%"/>
          </InputRow>
          <InputRow>  
            <Input type="text" label="Cidade" value={city} setValue={setCity} width="40%"/>
            <Input type="text" label="UF" value={UF} setValue={setUF} width="5%"/>
            <Input type="text" label="Número" value={number} setValue={setNumber} width="5%"/>
            <Input type="text" label="Complemento" value={complement} setValue={setComplement} width="40%"/>
          </InputRow>  
          <InputRow>
            <Input type="text" label="Contato" value={contact} setValue={setContact} width="25%"/>
            <Input type="text" label="Site" value={site} setValue={setSite} width="25%"/>
          </InputRow> */}

          <Divider />
          <ButtonSend>Incluir Empresa</ButtonSend>
        </form>
      </DefaultContainer>
    </>
  )
}