import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, NewButton } from "../../components/Defaults";
import Menu from "../../components/Menu";
import SearchInTable from "../../components/SearchInTable";
import Table from "../../components/Table";
import { GlobalContext } from '../../contexts/global';

export default function Actions() {
  const navigate = useNavigate();
  const { actions } = useContext(GlobalContext);
  const [filtered, setFiltered] = useState([]);
  
  console.log(actions);

  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Ações</h1>
        <Flex>
          <SearchInTable values={actions} setFiltered={setFiltered} filter="description" />
          {/* <NewButton onClick={() => navigate('novo')}>Incluir Grupo</NewButton> */}
        </Flex>
        <Table 
          header={[ 'Id','Descrição', 'É menu?', 'Qual Root', 'Tem Submenu?', 'Path']} 
          body={filtered.length ? filtered : actions}
          type="actions"
        />
      </DefaultContainer>
    </>
  )
}