import { useState } from "react";

import Menu from "../../../components/Menu";
import { DefaultContainer } from "../../../components/Defaults";
import Input from "../../../components/Input";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import fileDownload from "js-file-download";

export default function SearchAndTrucateCESTNoGTIN() {
  const [sped, setSped] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState({upload: 0, download: 0});

  function handle() {
    if(!isLoading && sped) {
      setIsLoading(true);
      const reader = new FileReader(); 
      reader.onload = (event) => {
        const textFromFileLoaded = event.target.result;
        const resolved = textFromFileLoaded.replace(/SEM GTIN/g, '');
        const blob = new Blob([resolved], {
          type: 'text/plain'
        });
        fileDownload(blob, sped.name.replace('.txt', '') + '.GTIN_ALTERADO.txt');
        setIsLoading(false)
      }
        reader.readAsText(sped, 'UTF-8');
    } else if (!sped) {
      alert('É necessário adicionar um arquivo!');
    }
  } 

  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1 style={{ marginBottom: 16 }}>Pesquisar e Truncar os SEM GTIN</h1>
        <Input type="file" accept="text/plain" label="SPED Fiscal" 
          setValue={setSped}
        />
        <ButtonWithLoading 
          onClick={handle}
          style={{ marginLeft: 'auto' }}
          isLoading={isLoading}
          text="Corrigir"
          progress={progress}
        />
      </DefaultContainer>
    </>
  )
}
