import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, InputRow } from "../../../components/Defaults";
import Menu from "../../../components/Menu";
import Input from "../../../components/Input";
import { changePassword, setNewPassword } from '../../../services/api';
import { GlobalContext } from '../../../contexts/global';

export default function ResetUserPassword() {
  const navigate = useNavigate();
  const { session, userToEdit } = useContext(GlobalContext);
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  
  function handleChangePassword() {
    if(newPass !== confirmNewPass) {
      alert('Verifique os campos, as senhas não coincidem!');
    } else {
      changePassword(userToEdit.id, newPass).then(json => {
        if(json.error) {
          alert(json.message);
        } else {
          navigate('/usuarios');
          alert(`A senha do usuário ${userToEdit.name} foi alterada com sucesso!`);
        }
      })
    }
  }

  return (
    <>
      <Menu />
      <DefaultContainer width="80%">
        <h1>Alterar senha: {userToEdit.name}</h1>
        <InputRow>
          <Input type="password" label="Senha Nova" value={newPass} setValue={setNewPass} />
          <Input type="password" label="Confirmar Senha Nova" value={confirmNewPass} setValue={setConfirmNewPass} />
        </InputRow>
          <button style={{ marginLeft: 'auto' }} onClick={handleChangePassword}>Enviar</button>
      </DefaultContainer>
    </>
  )
}