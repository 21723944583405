import { useState } from "react";

import Menu from "../../../components/Menu";
import { DefaultContainer } from "../../../components/Defaults";
import Table from "../../../components/Table";
import FilterSection from './components/FilterSection';
import ModalForm from './components/ModalForm';
import Modal from "../../../components/Modal";

export default function OperatorPanel() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [progress, setProgress] = useState({upload: 0, download: 0});

  const data = [
    {id: '1', name: 'Fulano José da silva', cnpj: '24.160.418/0001-24', insc: '0143290-77', pisconfins: 'DEVEDOR', valuePis: '129,12', valueCofins: '594,73', visto: '', regime: 'REAL', energy: 'SIM', obs: 'lorem ipsum'},
    {id: '2', name: 'Lorem Ipssum', cnpj: '24.160.418/0001-24', insc: '0143290-77', pisconfins: 'DEVEDOR', valuePis: '129,12', valueCofins: '594,73', visto: '', regime: 'REAL', energy: 'SIM', obs: 'lorem ipsum'},
    {id: '3', name: 'Teste', cnpj: '24.160.418/0001-24', insc: '0143290-77', pisconfins: 'DEVEDOR', valuePis: '129,12', valueCofins: '594,73', visto: '', regime: 'REAL', energy: 'SIM', obs: 'lorem ipsum'},
    {id: '4', name: 'Fulano José da silva', cnpj: '24.160.418/0001-24', insc: '0143290-77', pisconfins: 'DEVEDOR', valuePis: '129,12', valueCofins: '594,73', visto: '', regime: 'REAL', energy: 'SIM', obs: 'lorem ipsum'},
  ]
  const [body, setBody] = useState([]);

  function handleClickRow(row) {
    setSelectedItem(row);
    setIsModalOpen(true);
  }

  function handleFilter(name, month, year, situation) {
    setBody(() => {
      return data.filter(el => el.name.toUpperCase().startsWith(name.toUpperCase()));
    })
  }

  return (
    <>
      <Menu />
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <ModalForm selected={selectedItem} />
      </Modal>
      <DefaultContainer>
        <h1>Painel do Operador</h1>
        <FilterSection handleFilter={handleFilter} />
        <Table 
          header={[ 'Código', 'Razão', 'CNPJ', 'Insc. Est.', 'PIS/COFINS', 'Valor PIS', 'Valor COFINS', 'Visto', 'Regime', 'Energia', 'OBS']} 
          body={body}
          type="operatorPanel"
          onClickRow={handleClickRow}
        />
      </DefaultContainer>
    </>
  )
}
