import { TableWrapper } from "./styles";

export default function Table({header, body, onClickRow, actions, type}) {
  return (
    <TableWrapper>
      <table>
        <thead>
          <tr>
            {header.map(element => <th key={element}>{element}</th>)}
            {actions && <th>Ações</th>}
          </tr>
        </thead>
        <tbody>
          {type === 'operatorPanel' && 
            body.map(row => 
              <tr key={row.id} onClick={() => onClickRow(row)}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.cnpj}</td>
                <td>{row.insc}</td>
                <td>{row.pisconfins}</td>
                <td>{row.valuePis}</td>
                <td>{row.valueCofins}</td>
                <td>{row.visto}</td>
                <td>{row.regime}</td>
                <td>{row.energy}</td>
                <td>{row.obs}</td>
                {actions &&
                  <td>
                    <button>Editar</button>
                  </td>
                }
              </tr>  
            )
          }
          {type === 'companies' &&
            body.map((row, index) => 
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.corporateName}</td>
                <td>{row.fantasyName}</td>
                <td>{row.cnpj}</td>
              </tr>  
            )
          }
          {type === 'users' &&
            body.map((row, index) => 
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.group}</td>
                {actions &&
                  <>
                    <td className="actions">
                      <button onClick={() => actions.edit(row)}>Editar</button>
                      <button className="edit" onClick={() => actions.navigateToChangePassword(row)}>Alterar senha</button>
                    </td>
                  </>
                }
              </tr>
            )
          }
          {type === 'mobileUsers' &&
            body.map((row, index) => 
              <tr key={index} className={row.toAprove ? 'confirm' : ''}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.isDev ? 'Sim' : 'Não'}</td>
                <td>{row.obs}</td>
                {actions &&
                  <>
                    <td className="actions">
                      <button onClick={() => actions.delete(row)}>Inativar</button>
                      <button className="edit" onClick={() => actions.edit(row)}>Editar</button>
                      {row.toAprove &&
                        <button className="confirm" onClick={() => actions.aprove(row)}>Habilitar</button>
                      }
                    </td>
                  </>
                }
              </tr>
            )
          }
          {type === 'groups' &&
            body.map((row, index) => 
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.label}</td>
              </tr>
            )
          }
          {type === 'actions' &&
            body.map((row, index) => 
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.description}</td>
                <td>{row.isMenu ? 'Sim' : 'Não'}</td>
                <td>{row.root || ''}</td>
                <td>{row.haveSubmenu ? 'Sim' : 'Não'}</td>
                <td>{row.path}</td>
              </tr>
            )
          }
        </tbody>
      </table>
    </TableWrapper>
  )
}