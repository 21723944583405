import styled from "styled-components";

export const DashboardWrapper = styled.div`
  display: flex;
  width: 100%;

  img {
    width: 50%;
    margin: auto;
    margin-top: 10rem;
    filter: drop-shadow(1px 1px 2px black) 
            drop-shadow(-1px -1px 2px black);
  }
`