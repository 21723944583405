import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --white: #eee;
    --black: #202020;
    --graylight: #cacaca;
    --red: #952927;
  }
  .animationShow {
    opacity: 0;
    animation: animation 0.5s; 
  }
  @keyframes animation {
  to {
    opacity: 1;
    transform: initial;
  }
}
  // Modal Animations
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
  .ReactModal__Overlay--after-open{
      opacity: 1;
  }
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  .react-responsive-modal-container > .react-responsive-modal-modal {
    background: var(--graylight);
    border-radius: 10px;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
  }
  
  html {
    @media (max-width: 1080px){
      font-size: 93.75%;
    }
    @media (max-width: 720px){
      font-size: 87.5%;
    }
  }

  body {
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  input:not([type=checkbox]), textarea {
    background-color: var(--white);
    border-radius: 6px;
    border: 0;
    box-sizing: border-box;
    color: var(--black);
    font-size: 18px;
    outline: 0;
    padding: 7px;
    border: 2px solid var(--white);
    height: 2.5rem;
    :focus {
      border-color: var(--red);
    }
    resize: none;
  }
  textarea {
    height: auto;
  }
  
  select {
    height: 2.5rem;
    padding-left: 10px;
    
    background-color: var(--white);
    border-radius: 6px;
    box-sizing: border-box;
    color: var(--black);
    font-size: 18px;
    border: 2px solid var(--white);
    :focus {
        border: 2px solid var(--red);
    }

    option {
      color: #202020;
    }
  }

  button {
    display: flex;
    cursor: pointer;
    border: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--red);
    color: var(--white);
    padding: 0  0.5rem;
    min-width: 48px;
    min-height: 40px;
    font-size: 1.5rem;
    font-weight: bold;

    :hover {
      filter: brightness(1.2);
    }

    &.full {
      width: 100%;
      margin: 0.5rem 0;
    }

    &.medium {
      width: 25%;
      margin: 0.5rem auto;
    }
  }

  label {
    font-size: 1.1rem;
    font-weight: bold;
  }

  form.swalForm {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;
  }

  label.checkbox {
    display: flex;
    align-items: center;
    
    &:not(:first-child) {
      margin-left: 2rem;
    }

    &.vertical {
      margin-left: 0;
      margin: 0.5rem 0;
    }

    input {
      margin-right: 0.25rem;
    }
  }
`;
