import { InputRow } from './styles';

export default function Input(props) {
  const { type, options, label, value, setValue, button, accept } = props;
  return (
    <InputRow {...props}>
      {type === 'select' && (
        <>
          <label htmlFor={label}>{label}</label>
          <div className="select-wrapper">
            <select name={label} id={label} value={value} onChange={e => setValue(e.target.value)}>
              {options.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
            {button && (
              <button onClick={button} type="button">
                +
              </button>
            )}
          </div>
        </>
      )}
      
      {type === 'text' && (
        <DefaultInput label={label} type={type} value={value} setValue={setValue} />
      )}

      {type === 'email' && (
        <DefaultInput label={label} type={type} value={value} setValue={setValue} />
      )}

      {type === 'password' && (
        <DefaultInput label={label} type={type} value={value} setValue={setValue} maxLength={20} />
      )}

      {type === 'date' && (
        <DefaultInput label={label} type={type} value={value} setValue={setValue} />
      )}

      {type === 'file' && (
        <>
          <label htmlFor={label}>{label}</label>
          <input accept={accept} type={type} onChange={e => setValue(e.target.files[0])} />
        </>
      )}
      
      {type === 'number' && (
        <>
          <label>{label}</label>
          <input
            style={{ width: '5rem', textAlign: 'center' }}
            type={type}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </>
      )}

      {type === 'textarea' && (
        <>
          <label htmlFor={label}>{label}</label>
          <textarea type="text" rows={5} value={value} onChange={e => setValue(e.target.value)} />
        </>
      )}
    </InputRow>
  );
}

function DefaultInput(props) {
  const { label, type, value, setValue, accept } = props;
  return (
    <>
      <label htmlFor={label}>{label}</label>
      <input {...props} onChange={e => setValue(e.target.value)} />
    </>
  );
}
