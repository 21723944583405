import styled from 'styled-components'

export const FilterSectionWrapper =  styled.section`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
 
  label {
    margin-right: 3rem;
    select, input {
      margin-left: 0.5rem;
    }

    input {
      width: 20rem;
    }
  }
  button {
    margin-left: auto;
    width: 15rem;
  }
  
`