import axios from 'axios';
// const baseURL = 'http://teamcontabilidade.net/ws';
// const baseURL = 'https://127.0.0.1/ws';
const baseURL = 'http://saec.net.br/ws';

async function defaultRequest(xmls, setProgress) {  
  const soap = `<?xml version="1.0" encoding="UTF-8"?>
    <SOAP-ENV:Envelope
      xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/"
      SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
    <SOAP-ENV:Body>
      ${xmls}
    </SOAP-ENV:Body>
    </SOAP-ENV:Envelope>`;

  try {
    const response = await axios.post(`${baseURL}/saec_soapserver.php?wsdl`, soap, {
      headers: { 'Content-Type': 'application/xml' },
      onUploadProgress: (e) => {
        if(setProgress) {
          const upload = Math.round(
            (e.loaded * 100) / e.total
          );
          setProgress(old => ({...old, upload}));
        }
      },
      onDownloadProgress: (e) => {
        if(setProgress) {
          const download = Math.round(
            (e.loaded * 100) / e.total
          ); 
          setProgress(old => ({...old, download}));
        }
      }
    });

    baseURL === 'https://127.0.0.1/ws' && console.log('requisição feita localmente!');
    console.log(response.data);

    const startIndex = response.data.indexOf('<return xsi:type="xsd:string">') + '<return xsi:type="xsd:string">'.length;
    const endIndex = response.data.indexOf('</return>');
    const jsonString = response.data.substring(startIndex, endIndex);
    const json = JSON.parse(jsonString);

    if (json.err_msg) {
      return {
        error: true,
        message: `${json.err_msg}`
      };
    } else {
      return {
        error: false,
        data: json
      };
    }
  } 
  catch (error) {
    baseURL === 'http://localhost/pode/ws' && alert('requisição feita localmente!');
    console.log(`ERRO_______________________`);
    console.log(xmls);
    console.log(error);
    return {
      error: true,
      message: `${error}`
    };
  }
}

export async function getMobileUsers() {  
  const xmls = `<m:getUsuariosJSON xmlns:m="http://associacaopode.org.br/webservice/getUsuariosJSON" />`
  const soap = `<?xml version="1.0" encoding="UTF-8"?>
    <SOAP-ENV:Envelope
      xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/"
      SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
    <SOAP-ENV:Body>
      ${xmls}
    </SOAP-ENV:Body>
    </SOAP-ENV:Envelope>`;

  try {
    const response = await axios.post(`http://associacaopode.org.br/webservice/team_soapserver.php?wsdl`, soap, {
      headers: { 'Content-Type': 'application/xml' },
      
    });

    console.log(response.data);

    const startIndex = response.data.indexOf('<return xsi:type="xsd:string">') + '<return xsi:type="xsd:string">'.length;
    const endIndex = response.data.indexOf('</return>');
    const jsonString = response.data.substring(startIndex, endIndex);
    const json = JSON.parse(jsonString);

    if (json.err_msg) {
      return {
        error: true,
        message: `${json.err_msg}`
      };
    } else {
      return {
        error: false,
        data: json
      };
    }
  } 
  catch (error) {
    baseURL === 'http://localhost/pode/ws' && alert('requisição feita localmente!');
    console.log(`ERRO_______________________`);
    console.log(xmls);
    console.log(error);
    return {
      error: true,
      message: `${error}`
    };
  }
}

export async function login(email, password, setProgress) {
  const xmls = `<m:SAEC_getUserOkJSON xmlns:m="${baseURL}/SAEC_getUserOkJSON">
    <eml>${email}</eml>
    <pwd>${password}</pwd>
  </m:SAEC_getUserOkJSON>`;

  return defaultRequest(xmls, setProgress);
}

export async function changePassword(userId, password) {
  const xmls = `<m:SAEC_setUSR_SenhaJSON xmlns:m="${baseURL}/SAEC_setUSR_SenhaJSON">
    <cod>${userId}</cod>
    <pwd>${password}</pwd>
  </m:SAEC_setUSR_SenhaJSON>`;

  return defaultRequest(xmls);
}

export async function aproveMobileUser(userId) {
  const xmls = `<m:setUsrLiberarJSON xmlns:m="$http://associacaopode.org.br/webservice/setUsrLiberarJSON">
    <cod>${userId}</cod>
  </m:setUsrLiberarJSON>`;

  const soap = `<?xml version="1.0" encoding="UTF-8"?>
    <SOAP-ENV:Envelope
      xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/"
      SOAP-ENV:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
    <SOAP-ENV:Body>
      ${xmls}
    </SOAP-ENV:Body>
    </SOAP-ENV:Envelope>`;

  try {
    const response = await axios.post(`http://associacaopode.org.br/webservice/team_soapserver.php?wsdl`, soap, {
      headers: { 'Content-Type': 'application/xml' },
      
    });

    console.log(response.data);

    const startIndex = response.data.indexOf('<return xsi:type="xsd:string">') + '<return xsi:type="xsd:string">'.length;
    const endIndex = response.data.indexOf('</return>');
    const jsonString = response.data.substring(startIndex, endIndex);
    const json = JSON.parse(jsonString);

    if (json.err_msg) {
      return {
        error: true,
        message: `${json.err_msg}`
      };
    } else {
      return {
        error: false,
        data: json
      };
    }
  } 
  catch (error) {
    baseURL === 'http://localhost/pode/ws' && alert('requisição feita localmente!');
    console.log(`ERRO_______________________`);
    console.log(xmls);
    console.log(error);
    return {
      error: true,
      message: `${error}`
    };
  }
}

export async function cestInvalidos(sped, cests, setProgress) {
  const xmls = `<m:SAEC_fixCESTJSON xmlns:m="${baseURL}/SAEC_fixCESTJSON">
    <file_name>${sped.name.replace(/&/g, '%26')}</file_name>
    <file_content>${sped.content.replace(/&/g, '%26')}</file_content>
  </m:SAEC_fixCESTJSON>`;

  return defaultRequest(xmls, setProgress);
}

export async function getCompanies(/*setProgress*/) {
  const xmls = `<m:SAEC_getEmpresasJSON xmlns:m="${baseURL}/SAEC_getEmpresasJSON" />`
  return defaultRequest(xmls);
}

export async function getUsers(/*setProgress*/) {
  const xmls = `<m:SAEC_getUsuariosJSON xmlns:m="${baseURL}/SAEC_getUsuariosJSON" />`
  return defaultRequest(xmls);
}

export async function setUser(newUser) {
  const xmls = `<m:SAEC_setUsuarioJSON xmlns:m="${baseURL}/SAEC_setUsuarioJSON">
    <obj>${JSON.stringify(newUser)}</obj>
  </m:SAEC_setUsuarioJSON>`;
  return defaultRequest(xmls);
}

export async function setNewPassword(obj) {
  const xmls = `<m:SAEC_setUserPwdJSON xmlns:m="${baseURL}/SAEC_setUserPwdJSON">
    <obj>${JSON.stringify(obj)}</obj>
  </m:SAEC_setUserPwdJSON>`;
  return defaultRequest(xmls);
}

export async function getGroups(/*setProgress*/) {
  const xmls = `<m:SAEC_getGruposJSON xmlns:m="${baseURL}/SAEC_getGruposJSON" />`
  return defaultRequest(xmls);
}

export async function getActions(/*setProgress*/) {
  const xmls = `<m:SAEC_getAcoesJSON xmlns:m="${baseURL}/SAEC_getAcoesJSON" />`
  return defaultRequest(xmls);
}
// export async function teste(foo) {
//   const xmls = `<m:SAEC_testeJSON xmlns:m="${baseURL}/SAEC_testeJSON">
//     <file_name>${foo.name}</file_name>
//     <file_content>${encodeURIComponent(foo.content)}</file_content>
//   </m:SAEC_testeJSON>`;

//   return defaultRequest(xmls);
// }