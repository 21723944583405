import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { GlobalContext } from '../../contexts/global';
import packageJson from '../../../package.json';
import { Exit, MenuContainer, MenuItem, SubMenuContent } from './styles';
import LogoIcon from '../../assets/tcicon.png';

export default function Menu() {
  const { menuButtons, session } = useContext(GlobalContext);
  const navigate = useNavigate();

  function handleLogin(e) {
    console.log(e);
  }

  function handleNavigate(dialog, path) {
    console.log(path);
    if (dialog) {
      Swal.fire({
        icon: 'error',
        html:
          '<form class="swalForm" onsubmit="handleLogin()">' +
          '<label>Email</label>' +
          '<input />' +
          '<label>Senha</label>' +
          '<input />' +
          '</form>',
        showCloseButton: true,
        confirmButtonColor: '#952927',
        focusConfirm: false,
        confirmButtonText: 'Dar permissão',
      });
    } else if (path) {
      navigate(path);
    }
  }

  function renderSubItems(subItems) {
    return subItems?.map(item => (
        <span key={item.id} onClick={() => handleNavigate(item.showDialog, item.path)}>
          {item.title}
          {item.subItems?.length ? <div className='arrow'>➤</div> : ''}
          {item.subItems?.length 
            ? <div className='subItemsWrapper'>
                {renderSubItems(item.subItems)}
              </div>
            : ''
          }
        </span>
      ))
  }
  function renderMenuButtons() {
    return (
      menuButtons.map(button => (
        <div key={button.title}>
          <MenuItem>
            {button.title}
            <span className="marker" />
            <div className="contentWrapper">
              <SubMenuContent>
                {renderSubItems(button.subItems)}
              </SubMenuContent>
            </div>
          </MenuItem>
        </div>
      ))
    );
  }

  console.log(session);

  return (
    <MenuContainer>
      <Link to="/painel">
        <img src={LogoIcon} alt="Logo" />
      </Link>
      {renderMenuButtons()}
      <span className="user">{session.name}</span>
      <span className="version">v{packageJson.version}</span>
      <Exit to="/">
        Sair
      </Exit>
    </MenuContainer>
  );
}