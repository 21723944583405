import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Divider, InputRow, ButtonSend } from "../../../components/Defaults";
import { CompaniesWrapper } from './styles';
import Menu from '../../../components/Menu';
import Input from '../../../components/Input';
import { DiffRemoved } from '@styled-icons/octicons';
import { DiffAdded } from '@styled-icons/octicons';
import { GlobalContext } from "../../../contexts/global";
import { setUser } from "../../../services/api";

export default function NewMobileUser() {
  const {groups, companies, userToEdit, setUserToEdit} = useContext(GlobalContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [uid, setUID] = useState('');
  const [isDriver, setIsDriver] = useState(false);
  const fomarmattedCompanies = companies.map(company => ({
    ...company,
    label: `${company.id} - ${company.fantasyName}`
  }));  
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [group, setGroup] = useState(groups[0].id);
  const [email, setEmail] = useState('');

  function handleSend(e) {
    e.preventDefault();
    if(!name || !email) {
      alert('Preencha as informações!');
    } else {
      const newUser = {
        USR_CODIGO: userToEdit ? userToEdit.id : null,
        USR_EMAIL: email,
        USR_GRUPO: group,
        USR_NOME: name,
        USR_EMPRESAS: selectedCompanies.map(company => ({
          EMP_CODIGO: company.id,
          EMP_FANTASIA: company.fantasyName,
          EMP_RAZAO: company.corporateName,
          EMP_CNPJ: company.cnpj
        }))
      }
      setUser(newUser).then(json => {
        if(json.error) {
          alert('Falha ao cadastrar usuário!');
        } else {
          alert(`Usuário ${userToEdit ? 'editado' : 'cadastrado'} com sucesso!`);
          setUserToEdit(null);
          setName('');
          setEmail('');
          setGroup(groups[0].id);
          setSelectedCompanies([]);
          navigate('/usuarios');
        }
      })
    }
  }

  return (
    <>
      <Menu />
      <DefaultContainer width="75%">
        {userToEdit
          ? <h1>Edição do Colaborador: {userToEdit.name}</h1>
          : <h1>Cadastro de Usuário Mobile</h1>
        }
        <form onSubmit={handleSend}>
          <InputRow>
            <Input type="text" label="Nome" value={name} setValue={setName} />
            <Input type="email" label="Email" value={email} setValue={setEmail} />
            <Input type="password" label="Senha" value={password} setValue={setPassword} />
          </InputRow>
          <InputRow>
            <Input type="text" label="UID" value={uid} setValue={setUID} />
            <div className="radioGroup">
              <label>Motorista: </label>
              <span onClick={() => setIsDriver(true)}>
                <input type="radio" checked={isDriver} onChange={e => setIsDriver(true)}/>
                Sim
              </span>
              <span onClick={() => setIsDriver(false)}> 
                <input type="radio" checked={!isDriver} onChange={e => setIsDriver(false)}/>
                Não
              </span>
            </div>
          </InputRow>
          <ButtonSend>Incluir Usuário</ButtonSend>
        </form>
      </DefaultContainer>
    </>
  )
}