import { useState } from "react";
import { ModalFormWrapper } from "./styles";
import { useEffect } from "react";

export default function ModalForm({selected}) {
  const situations = ['SELECIONE', 'DEVEDOR', 'CREDOR'];
  const energies = ['SELECIONE', 'SIM', 'NÃO'];
  const regimes = ['SELECIONE', 'REAL', 'PRESUMIDO'];
  const [email, setEmail] = useState('');
  const [pisconfins, setPisconfins] = useState('');
  const [valuePis, setValuePis] = useState('');
  const [valueCofins, setValueCofins] = useState('');
  const [visto, setVisto] = useState('');
  const [regime, setRegime] = useState('');
  const [energy, setEnergy] = useState('');
  const [obs, setObs] = useState('');
  
  useEffect(() => {
    setEmail(selected.email);
    setPisconfins(selected.pisconfins);
    setValuePis(selected.valuePis);
    setValueCofins(selected.valueCofins);
    setVisto(selected.visto);
    setRegime(selected.regime);
    setEnergy(selected.energy);
    setObs(selected.obs);
  }, []); 

  return (
    <ModalFormWrapper>
      <h2>Empresa</h2>
      <div className="infos">
        <span>Razão: </span>
        <span>{selected.name}</span>
      </div>
      <div className="infos">
        <span>CNPJ: </span>
        <span>{selected.cnpj}</span>
      </div>
      <div className="infos">
        <span>Insc: </span>
        <span>{selected.insc}</span>
      </div>

      <h2 className="notInfos">Informações de PIS/COFINS</h2>
      <div className="row">
        <label>
          <span>Valor PIS:</span> 
          <input value={valuePis} onChange={(e) => setValuePis(e.target.value)} />
        </label>
        <label>
          <span>Visto: </span>
          <input value={visto} onChange={(e) => setVisto(e.target.value)} />
        </label>
      </div>
      <div className="row">
        <label>
          <span>Valor COFINS:</span>
          <input value={valueCofins} onChange={(e) => setValueCofins(e.target.value)} />
        </label>
        <label>
          <span>Regime:</span>
          <select value={regime} onChange={(e) => setRegime(e.target.value)}>
            {regimes.map(item => <option key={item} value={item}>{item}</option>)}
          </select>
        </label>
      </div>

      <div className="row">
        <label>
          <span>Situação:</span> 
          <select value={pisconfins} onChange={(e) => setPisconfins(e.target.value)}>
            {situations.map(item => <option key={item} value={item}>{item}</option>)}
          </select>
        </label>
        <label>
          <span>Energia:</span> 
          <select value={energy} onChange={(e) => setEnergy(e.target.value)}>
            {energies.map(item => <option key={item} value={item}>{item}</option>)}
          </select>
        </label>
      </div>

      <div className="row">
        <label className="full">
          <span>Observação:</span>
          <input className="full" value={obs} onChange={(e) => setObs(e.target.value)} />
        </label>
      </div>
      <div className="row">
        <label className="full">
          <span>E-mail:</span>
          <input className="full" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
      </div>

      <button>Atualizar</button>
    </ModalFormWrapper>
  )
}