import { useState } from "react";
import { SearchInTableWrapper } from "./styles";
import { Search } from '@styled-icons/feather';

export default function SearchInTable({values, setFiltered, filter}) {
  const [filterText, setText] = useState('');

  function handleFilter() {
    const filtered = values.filter(element => 
      element[filter].toUpperCase().startsWith(filterText.toUpperCase())
    );
    setFiltered(filtered);
  }

  function handleKeyPress(event) {
    event.key === 'Enter' && handleFilter();
  }

  return (
    <SearchInTableWrapper>
      <input value={filterText} onChange={e => setText(e.target.value)} placeholder="Buscar" autoFocus onKeyDown={handleKeyPress}/>
      <button onClick={handleFilter}>
        <Search size={30} />
      </button>
    </SearchInTableWrapper>
  )
}