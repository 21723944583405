import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  background: var(--black);
  min-height: 100vh;
  width: 100vw;
  margin: auto;
  padding: 1rem;
  color: var(--white);

  > img {
    width: 40%;
    margin: auto;
    @media (max-width: 900px) {
      display: none;
    }
  }

  img.logo {
    margin-bottom: 2rem;
  }

  button {
    color: var(--white);
  }

  span.version {
    position: absolute;
    color: #777;
    bottom: 2px;
    font-size: 0.9rem;
  }

  label.remember {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    input {
      margin-right: 0.5rem;
    }
  }
`