import styled from "styled-components";

export const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0.5rem;
  border: 2px solid black;
  border-radius: 5px;
  margin-top: 1rem;
  min-height: 10rem;
  
  div {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background: var(--white);
    border-bottom: 2px solid var(--red);
    border-radius: 2px;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 2rem;
    margin: auto;
    color: #777;
  }

  svg {
    cursor: pointer;
    margin-right: 1rem;

    :hover {
      filter: brightness(1.5);
    }
  }
`