import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuContainer = styled.nav`
  width: 100%;
  background: var(--red);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  img {
    width: 50px;
    margin-left: 1rem;
    transition: 0.3s;

    &:hover {
      filter: brightness(1.2);
    }
  }

  span.user {
    position: absolute;
    right: 4.5rem;
    color: #fff;
    font-weight: bold;
    /* bottom: 2px; */
    border-right: 2px solid #333;
    padding-right: 1rem;
    /* font-size: 0.8rem; */
  }

  span.version {
    position: absolute;
    right: 1rem;
    color: #999;
    font-weight: bold;
    bottom: 2px;
    font-size: 0.8rem;
  }
`;

export const MenuItem = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--red);
  /* border-bottom: 2px solid var(--black); */
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 0;
  transition: 0.3s;
  position: relative;
  color: var(--white);
  width: 8rem;
  z-index: 2;

  span.marker {
    width: 25px;
    height: 25px;
    background: var(--red);
    position: absolute;
    top: calc(100% - 12px);
    transform: rotate(45deg);
    display: none;
    border-bottom-right-radius: 5px;
  }
  :hover div.contentWrapper,
  :hover span.marker {
    display: flex;
  }
  div.contentWrapper {
    padding: 10px;
    opacity: 0;
    animation: animation 0.7s forwards;
    position: absolute;
    top: 100%;
    display: none;
  }
  img {
    width: 40px;
    margin-bottom: 5px;
  }
`;

export const SubMenuContent = styled.div`
  display: flex;
  background: var(--red);
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  /* max-width: 404px; */
  flex-direction: column;
  box-shadow: 5px 5px 4px #0008;

  span {
    position: relative;
    text-decoration: none;
    color: var(--white);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 10rem;
    border-bottom: 2px solid var(--black);
    background: var(--red);
    text-align: center;

    div.arrow {
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translate(-50%, -50%);
      /* color: var(--red); */
    }
    :hover {
      filter: brightness(1.1);
    }

    :hover div.subItemsWrapper {
      display: flex;
    }
  
    div.subItemsWrapper {
      position: absolute;
      left: calc(99%);
      justify-content: center;
      flex-direction: column;
      align-items: center;
      top: 0;
      display: none;
      padding: 1rem;
    }
  }

`;

export const Exit = styled(Link)`
  border-bottom: 1px solid #0000;
  justify-content: center;
  color: var(--white);
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
  transition: 0.2s;
  margin-left: auto;
  margin-right: 1rem;
  :hover {
    transition: 0.2s;
    border-bottom: 1px solid var(--white);
  }
`;