import { useRef } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal} from 'react-responsive-modal';

export default function Modal({ isOpen, setIsOpen, children }) {
  const ref = useRef(null);

  return (
    <ResponsiveModal open={isOpen} onClose={() => setIsOpen(false)} center 
      ref={ref}
      onAnimationEnd={() => {
        if (!isOpen) {
          if (ref.current) {
            ref.current.style.opacity = "0%";
          }
          if (ref.current?.parentElement?.parentElement) {
            ref.current.parentElement.parentElement.style.opacity = "0%";
          }
        }
      }}
    >
      {children}
    </ResponsiveModal>
  )
}