import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, NewButton } from "../../components/Defaults";
import Menu from "../../components/Menu";
import SearchInTable from "../../components/SearchInTable";
import Table from "../../components/Table";
import { GlobalContext } from '../../contexts/global';

export default function Groups() {
  const navigate = useNavigate();
  const { groups } = useContext(GlobalContext);
  const [filtered, setFiltered] = useState([]);
  
  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Grupos</h1>
        <Flex>
          <SearchInTable values={groups} setFiltered={setFiltered} filter="description" />
          {/* <NewButton onClick={() => navigate('novo')}>Incluir Perfil</NewButton> */}
        </Flex>
        <Table 
          header={[ 'Id','Descrição']} 
          body={filtered.length ? filtered : groups}
          type="groups"
        />
      </DefaultContainer>
    </>
  )
}