import styled from 'styled-components';

export const Form = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  margin: auto;

  > button {
    display: flex;
    margin: auto;
    margin-top: 2rem;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0.5rem;
`;

export const DefaultContainer = styled.main`
  padding: 1rem 2rem;
  margin: auto;
  margin-top: 1rem;
  width: ${props => props.width || '90%'};
  background: var(--graylight);
  box-shadow: 0px 0px 10px #555;

  h1 {
    font-size: 2.5rem;
    color: var(--black);
    margin-bottom: 1rem;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => 
    props.center ? 'center' :
    props.between ? 'space-between' : 
    props.end ? 'flex-end' : 'flex-start'
  };
`

export const NewButton = styled.button`
  font-size: 1rem; 
  width: 15rem;
`

export const InputRow = styled(Flex)`
  div.radioGroup {
    font-weight: bold;
    display: flex;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      input {
        margin-left: 0.75rem;
        margin-right: 0.25rem;
      }
    }
  }

  &.center {
    justify-content: center;
  }

  > div:not(:last-child) {
    margin-right: 1rem;
  }

  button {
    margin-top: 0.5rem;
  }
`

export const Divider = styled.span`
  display: flex;
  flex: 1;
  height: 2px;
  background: var(--red);
  margin: 1rem;
`

export const DividerNoMargin = styled(Divider)`
  margin: 0;
`

export const ButtonSend = styled.button`
  margin: auto;
  margin-top: 2rem;
`