import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultContainer, Flex, InputRow } from "../../components/Defaults";
import Menu from "../../components/Menu";
import Input from "../../components/Input";
import { setNewPassword } from '../../services/api';
import { GlobalContext } from '../../contexts/global';

export default function ChangePassword() {
  const navigate = useNavigate();
  const { session } = useContext(GlobalContext);
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  
  function handleChangePassword() {
    const obj = {
      USR_CODIGO: session.id,
      OLD_PWD: pass,
      NEW_PWD: newPass
    }
    setNewPassword(obj).then(json => {
      if(json.error) {
        alert('Falha ao mudar senha!');
      } else {
        alert('Senha alterada com sucesso!');
        setPass('');
        setNewPass('');
        setConfirmNewPass('');
      }
    })
  }

  return (
    <>
      <Menu />
      <DefaultContainer width="80%">
        <h1>Alterar Senha</h1>
        <InputRow>
          <Input type="password" label="Senha Antiga" value={pass} setValue={setPass} />
          <Input type="password" label="Senha Nova" value={newPass} setValue={setNewPass} />
          <Input type="password" label="Confirmar Senha Nova" value={confirmNewPass} setValue={setConfirmNewPass} />
        </InputRow>
        <button style={{ marginLeft: 'auto' }} onClick={handleChangePassword}>Enviar</button>
      </DefaultContainer>
    </>
  )
}