import styled from 'styled-components';

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => props.width ? props.width : '100%'};
  margin-bottom: 0.75rem;

  div.select-wrapper {
    display: flex;

    select {
      width: 100%;
    }

    button {
      margin-left: 0.5rem;
      width: 4rem;
      font-size: 1.5rem;
      border-radius: 6px;
    }
  }

  label {
    margin-bottom: 2px;
  }
`;
