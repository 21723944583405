import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftSquareFill } from '@styled-icons/bootstrap';
import { ArrowRightSquareFill } from '@styled-icons/bootstrap';

import { DefaultContainer, Flex } from "../../components/Defaults";
import Menu from "../../components/Menu";
import { GlobalContext } from '../../contexts/global';
import { ActionsWrapper, ButtonsWrapper, GroupsWrapper } from './styles';

export default function Permissions() {
  const navigate = useNavigate();
  const { groups, actions } = useContext(GlobalContext);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Permissões</h1>
        <GroupsWrapper>
          {groups.map(group => 
            <li key={group.id} className={group.id == selectedGroup?.id ? 'selected' : ''} onClick={() => setSelectedGroup(group)}>
              {group.label}
            </li>  
          )}
        </GroupsWrapper>
        <Flex>
          <ActionsWrapper>
            <h2>{selectedGroup?.label}</h2>
            {selectedGroup?.permissions.map(action => 
              <li key={action.id} className={action.id == selectedAction?.id ? 'selected' : ''} onClick={() => setSelectedAction(action)}>
                {action.label}
              </li>
            )}
          </ActionsWrapper>

          <ButtonsWrapper>
            <button>
              <ArrowLeftSquareFill size={30} color="#fff"/>
            </button>
            <button>
              <ArrowRightSquareFill size={30} color="#fff" />
            </button>
          </ButtonsWrapper>

          <ActionsWrapper>
            <h2>Todas as Ações</h2>
            {actions.map(action => 
              <li key={action.id} className={action.id == selectedAction?.id ? 'selected' : ''} onClick={() => setSelectedAction(action)}>
                {action.description}
              </li>
            )}
          </ActionsWrapper>
        </Flex>
        <button style={{ marginLeft: 'auto', marginTop: 20 }}>Salvar</button>
      </DefaultContainer>
    </>
  )
}