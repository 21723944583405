import ReactLoading from 'react-loading';

export default function ButtonWithLoading(props) {
  const {isLoading, text, progress} = props;

  return (
    <button {...props} disabled={isLoading}>
      {isLoading
        ? 
        <>
          &nbsp;
          <ReactLoading type="spokes" color="#fff" height={30} width={30} />
          &nbsp;
        </>
        : text
      }
      {isLoading && progress?.upload < 100 && progress?.upload > 0 && `Enviando ${progress?.upload}%`}
      {isLoading && progress?.download < 100 && progress?.download > 0 && `Baixando ${progress?.download}%`}
    </button>
  )
}