import { useEffect, useState } from 'react';

import Menu from '../../components/Menu';
import { DashboardWrapper } from './styles';
import Logo from '../../assets/tclogo.png';

export default function Dashboard() {

  useEffect(() => {
    sessionStorage.removeItem('email');
  }, []);

  return (
    <>
      <Menu />
      <DashboardWrapper>
        <img src={Logo} />
      </DashboardWrapper>
    </>
  );
}
