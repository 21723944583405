import styled from "styled-components";

export const GroupsWrapper = styled.ul`
  width: 100%;
  max-height: 11rem;
  overflow-x: auto;
  margin-bottom: 1rem;
  li {
    background: #fff;
    list-style: none;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    font-weight: bold;
    cursor: pointer;

    &.selected {
      background: var(--red);
      color: #fff;

      :hover {
      filter: brightness(1);
      }
    }

    :hover {
      filter: brightness(0.7);
    }

  }
`

export const ActionsWrapper = styled(GroupsWrapper)`
  max-height: 10rem;
  height: 10rem;
  border: 2px solid var(--red);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0;
  
  h2 {
    margin-bottom: 1rem;
  }

`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  button {
    background: var(--red);
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin: 0.5rem 1rem;
        
  }
`