import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUsers } from "../../services/api";
import { DefaultContainer, Flex, NewButton } from "../../components/Defaults";
import Menu from "../../components/Menu";
import SearchInTable from "../../components/SearchInTable";
import Table from "../../components/Table";
import { GlobalContext } from '../../contexts/global';

export default function Employees() {
  const navigate = useNavigate();
  const { users, setUsers, setUserToEdit } = useContext(GlobalContext);
  const [filtered, setFiltered] = useState([]);

  function handleSetEditUser(user) {
    setUserToEdit(user);
    navigate('/cadastro-usuario')
  }

  function navigateToChangePassword(user) {
    setUserToEdit(user);
    navigate('/alterar-senha-usuario');
  }

  useEffect(() => {
    getUsers().then(json => {
      if(json.error) {
        alert('Falha ao resgatar usuários:', json.message);
      } else {
        setUsers(() => json.data.arrUsuarios.map(user => ({
          id: user.USR_CODIGO,
          email: user.USR_EMAIL,
          name: user.USR_NOME,
          group: user.USR_GRUPO,
          companies: user.arrEmpresas.map(company => ({
            id: company.EMP_CODIGO,
            fantasyName: company.EMP_FANTASIA,
            corporateName: company.EMP_RAZAO,
            cnpj: company.EMP_CNPJ,
            label: `${company.EMP_CODIGO} - ${company.EMP_FANTASIA}`
          }))
        })))
      }
    })
  }, []);

  return (
    <>
      <Menu />
      <DefaultContainer>
        <h1>Colaboradores</h1>
        <Flex>
          <SearchInTable values={users} setFiltered={setFiltered} filter="name" />
          <NewButton onClick={() => navigate('/cadastro-usuario')}>Incluir colaborador</NewButton>
        </Flex>
        <Table 
          header={[ 'Id','Nome', 'Email', 'Grupo']} 
          body={filtered.length ? filtered : users}
          type="users"
          actions={{edit: handleSetEditUser, navigateToChangePassword}}
        />
      </DefaultContainer>
    </>
  )
}